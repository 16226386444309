import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./Components/Auth/Login";
import Signup from "./Components/Auth/Signup";
import ForgotPassword from "./Components/Auth/ResetPassword";
import UserDashboard from "./Components/Dashboard/UserDashboard";
import DrafterDashboard from "./Components/Dashboard/DrafterDashboard";
import Viewer from "./Components/SVG/Viewer";
import { CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import PostHogPageviewTracker from "./utils/PostHogPageviewTracker";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import ProtectedRoutes from "./Components/Auth/ProtectedRoutes";

const theme = createTheme({
  typography: {
    fontFamily: "Montserrat, sans-serif",
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <ToastContainer
        position="bottom-right"
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
      />
      <CssBaseline />
      <Router>
        <PostHogPageviewTracker />
        <Routes>
          <Route path="/" element={<Login />} exact />
          <Route path="/signup" element={<Signup />} exact />
          <Route path="/forgotPassword" element={<ForgotPassword />} exact />
          <Route element={<ProtectedRoutes />}>
            <Route path="/userDashboard" element={<UserDashboard />} exact />
            <Route
              path="/drafterDashboard"
              element={<DrafterDashboard />}
              exact
            />
            <Route path="/userDrawing/:partID" element={<Viewer />} exact />
          </Route>
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
