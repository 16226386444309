import React, { useEffect, useState } from "react";
import {
  Container,
  Box,
  Typography,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Link,
  Paper,
  Grid,
  useMediaQuery,
  useTheme,
  InputAdornment,
  IconButton,
  RadioGroup,
  FormControl,
  FormLabel,
  Radio,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import EmailIcon from "@mui/icons-material/Email";
import KeyIcon from "@mui/icons-material/Key";
import { styled } from "@mui/material/styles";
import GoogleIcon from "@mui/icons-material/Google";
import axios from "axios";
import emailjs from "@emailjs/browser";
import BackgroundImage1 from "../../Assets/BG_1.jpg";
import { toast } from "react-toastify";

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(0.5),
  color: "#c76e00",
  backgroundColor: "#fff",
  border: "2px solid #c76e00",
  borderRadius: "10px",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "#f6f6f6",
    border: "1px solid #dadce0",
  },
}));

const StyledSubmitButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(0.5),
  color: "white",
  backgroundColor: "#c76e00",
  borderRadius: "10px",
  textTransform: "capitalize",
  "&:hover": {
    backgroundColor: "#e0e0e0",
  },
}));

const LoginTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#c76e00",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#c76e00",
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "#c76e00",
    },
  },
});

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [ConfirmPassword, setConfirmPassword] = useState("");
  const [role, setRole] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [items, setItems] = useState([]);
  const [otp, setOtp] = useState("");
  const [generatedOtp, setGeneratedOtp] = useState(null);
  const [otpSent, setOtpSent] = useState(false);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownConfirmPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  const sendOtp = () => {
    const generatedOtp = Math.floor(1000 + Math.random() * 9000).toString();
    setGeneratedOtp(generatedOtp);

    emailjs
      .send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID_OTP,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID_OTP,
        {
          message: `${generatedOtp}`,
          reply_to: email,
        },
        process.env.REACT_APP_EMAILJS_AUTHENTICATION_CODE_OTP
      )
      .then((response) => {
        toast.info("OTP Sent");
        setOtpSent(true);
      })
      .catch((error) => {
        console.error("Failed to send OTP:", error);
      });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append("email", email);
    formData.append("password", password);

    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_SERVER}/forgotPassword`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        toast.info("Password Updated Successfully");
        window.location.href = "/";
      }
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data);
      } else {
        toast.error("An Error Occurred");
      }
    }
  };

  const handleOtpSubmit = (event) => {
    event.preventDefault();
    if (otp === generatedOtp) {
      handleSubmit(event);
    } else {
      toast.error("Invalid Code");
    }
  };

  return (
    <Grid container style={{ height: "100vh" }}>
      {matches && (
        <Grid
          item
          md={5}
          sx={{
            display: { xs: "none", md: "flex" },
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              width: "100%",
              overflow: "hidden",
              backgroundColor: "#ff00ff",
            }}
          >
            <img
              src={BackgroundImage1}
              alt=""
              style={{
                width: "100vw",
                height: "100vh",
                objectFit: "fill",
              }}
            />
          </Box>
        </Grid>
      )}
      <Grid
        item
        xs={12}
        md={7}
        component="main"
        style={{
          backgroundColor: "#f7f7f7",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Container maxWidth="xs">
          <Paper
            elevation={0}
            sx={{
              mt: 8,
              display: "flex",
              flexDirection: "column",
              p: 2,
            }}
            style={{ padding: "5% 10%" }}
          >
            <b style={{ textAlign: "left", fontSize: "20px" }}>
              Forgot Password?
            </b>
            <p style={{ fontSize: "16px", marginTop: "2%" }}>
              Set a new password!
            </p>
            <Box
              component="form"
              onSubmit={otpSent ? handleOtpSubmit : sendOtp}
              sx={{ mt: 1 }}
            >
              <LoginTextField
                margin="normal"
                fullWidth
                id="email"
                label="Registered Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      style={{ color: "#c76e00" }}
                    >
                      <EmailIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <LoginTextField
                margin="normal"
                fullWidth
                name="password"
                label="Password"
                type={showPassword ? "text" : "password"}
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      style={{ color: "#c76e00" }}
                    >
                      <KeyIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        style={{ color: "#c76e00" }}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <LoginTextField
                margin="normal"
                fullWidth
                name="password"
                label="Confirm Password"
                type={showConfirmPassword ? "text" : "password"}
                id="password"
                autoComplete="current-password"
                value={ConfirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      style={{ color: "#c76e00" }}
                    >
                      <KeyIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowConfirmPassword}
                        onMouseDown={handleMouseDownConfirmPassword}
                        style={{ color: "#c76e00" }}
                      >
                        {showConfirmPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {otpSent && (
                <LoginTextField
                  margin="normal"
                  fullWidth
                  name="otp"
                  label="Enter Code"
                  type="text"
                  id="otp"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                  variant="outlined"
                />
              )}
              <StyledSubmitButton
                fullWidth
                style={{ marginTop: "10%" }}
                onClick={otpSent ? handleOtpSubmit : sendOtp}
              >
                <b>{otpSent ? "Verify Code" : "Get OTP on Email"}</b>
              </StyledSubmitButton>
            </Box>
          </Paper>
        </Container>
      </Grid>
    </Grid>
  );
};

export default ForgotPassword;
