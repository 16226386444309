import React from "react";
import Drawer from "@mui/material/Drawer";
import { Box, Button, Typography, Tooltip } from "@mui/material";
import { Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import WorkspacesIcon from "@mui/icons-material/Workspaces";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import Logo from "./../Assets/Logo.png";

const MyButton = styled(Button)(({ theme, clicked }) => ({
  height: "56px",
  width: "100%",
  padding: "10%",
  justifyContent: "flex-start",
  marginBottom: "8px",
  color: clicked ? theme.palette.common.white : theme.palette.text.primary,
  borderRadius: "16px",
  transition: "background-color 0.3s, color 0.3s",
  "&:hover": {
    backgroundColor: "#c76e00",
    color: theme.palette.common.white,
  },
  "&:active": {
    backgroundColor: "#c76e00",
    color: theme.palette.common.white,
  },
}));

const drawer = (
  <Stack
    component="section"
    bgcolor="#F4F4F4"
    direction="column"
    justifyContent="space-between"
    alignItems="center"
    spacing={12}
    sx={{ height: "100%" }}
    p={"12%"}
  >
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      sx={{ width: "100%" }}
    >
      <Box
        sx={{ mb: 5, pl: "10%", boxSizing: "border-box" }}
        display="flex"
        flexDirection="row"
        width={"100%"}
        alignItems={"center"}
      >
        <img
          src={Logo}
          style={{ width: "28px", height: "28px", marginRight: "16px" }}
          alt="Logo"
        />
        <Typography variant="h6" fontWeight="bold">
          Hanomi AI
        </Typography>
      </Box>
    </Stack>
    <Stack mb={20} sx={{ width: "100%" }}>
      <Box
        style={{
          display: "flex",
          justifyContent: "left",
          alignItems: "center",
          color: "#c76e00",
        }}
      >
        <Tooltip title="Logout" arrow>
          <MyButton
            sx={{
              color: "#c76e00",
              border: "1px solid #c76e00",
              "& .MuiButton-startIcon": { ml: 0, mr: 2 },
            }}
            startIcon={<LogoutIcon />}
            onClick={() => {
              localStorage.clear();
              window.location.href = "/";
            }}
          >
            Logout
          </MyButton>
        </Tooltip>
      </Box>
    </Stack>
  </Stack>
);

export default function SideNavBar({ open }) {
  return (
    <Drawer
      open={open}
      p={2}
      variant="permanent"
      sx={{
        display: { xs: "none", sm: "block" },
        "& .MuiDrawer-paper": {
          border: "none", 
          width: "300px",
          height: "100%",
          position: "fixed"
        },
      }}
    >
      {drawer}
    </Drawer>
  );
}
